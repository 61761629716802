.form-label.-alt {
    @include small-caps;
    font-weight: bold;
}

.form-group.-alt {
    margin-bottom: 1.5rem;

    .invalid-feedback {
        margin-top: 0.5rem;
    }
}

.form-control.-alt {
    border-color: transparent;
    border-radius: 0;
    border-bottom-color: $border-color;
    padding-left: 0;
    padding-right: 0;
    transition: all 200ms;
    background-color: transparent;

    &:focus {
        box-shadow: none;
        border-bottom-color: $primary;
    }

    &.is-invalid {
        border-bottom-color: $danger;
    }

    &.is-invalid:focus {
        box-shadow: none;
        border-color: transparent;
        border-bottom-color: $primary;
    }
}

.form-value {
    margin-bottom: 0;
    border-bottom: 1px solid transparentize($dark-blue, 0.8);
    padding: 0.375rem 0;
    height: 1.5rem;
    box-sizing: content-box;
    opacity: 0.6;
}

.radio {
    input {
        margin-right: 0.75rem;
    }
}

.count-input {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__button {
        @include circle(1.5rem);
        border: 2px solid $dark-blue;
        font-size: 1.05rem;
        font-weight: bold;
        text-align: center;

        &:disabled {
            opacity: 0.25;
        }

        &:not(:disabled):hover {
            background-color: $light-blue;
            border-color: $light-blue;
            color: $white;
        }

        &:focus {
            @include shadow;
        }
    }

    &__input {
        margin: 0 0.5rem;
        max-width: 3rem;
        text-align: center;
        padding-left: 0.2em;
        padding-right: 0.2em;
    }
}

.no-border {
    border: none;
}
