.businesses {
    --sidebar-width: 500px;
    height: calc(100vh - 4.5rem);

    &__card {
        @include shadow($y: -1);
        width: 100%;
        top: 100px;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 2rem 2rem 0 0;
        transition: transform 200ms ease-out;

        @include media-breakpoint-up(md) {
            width: var(--sidebar-width);
            top: 0;
            left: 0;
            bottom: 0;
            border-radius: 0;
            border: none;
        }
    }

    &--collapsed &__card {
        transform: translateY(calc(100vh - 220px));

        @include media-breakpoint-up(md) {
            transform: none;
        }
    }

    &__overlay {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &--collapsed &__overlay {
        display: none;
    }

    &__map {
        @include media-breakpoint-up(md) {
            padding-left: var(--sidebar-width);
        }
    }
}

.business-filters {
    background-color: white;
    border-radius: 0.25rem;
    padding: 2rem;

    .-category-icon {
        background-color: $secondary;
        display: inline-block;
        height: 24px;
        margin-left: 4px;
        width: 24px;
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: 22px 22px;

        &.starred {
            background-color: $orange;
            margin-left: -4px;
        }
    }

    .-category-starred {
        background: url("/img/icons/star.svg") no-repeat center top;
        display: inline-block;
        width: 10px;
        height: 24px;
    }

    .form-group {
        margin-bottom: 0;
    }

    .torres-checkbox .-label {
        font-weight: bold;
        vertical-align: top;
    }
}

.business-detail {
    .header-image {
        display: block;
        height: 300px;
        width: 100%;
        object-fit: cover;
    }

    .section {
        display: block;
        padding: 1.5rem;

        &:not(:last-child) {
            border-bottom: 1px solid $whitesmoke;
        }
    }

    .subheading {
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.875rem;
        margin-bottom: 1.5rem;
    }

    .actions-grid {
        display: grid;
        gap: 0.5rem;
    }

    .gallery-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    .gallery-grid-item {
        display: block;
        width: 120px;
        height: 120px;
        object-fit: cover;
        flex-shrink: 0;
    }
}
