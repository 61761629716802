// -----------------------------------------------------------------------------
// Position
// -----------------------------------------------------------------------------

@mixin inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.inset-0 {
    @include inset-0;
}

.top-0 {
    top: 0;
}

.right-0 {
    right: 0;
}

.bottom-0 {
    bottom: 0;
}

.left-0 {
    left: 0;
}

.z-10 {
    z-index: 10;
}

// -----------------------------------------------------------------------------
// Columns
// -----------------------------------------------------------------------------

.columns-md-2 {
    @include media-breakpoint-up(md) {
        columns: 2;
    }
}

.break-inside-avoid {
    break-inside: avoid;
}

// -----------------------------------------------------------------------------
// Shadow
// -----------------------------------------------------------------------------

$shadow-factor: 0.5;

@mixin shadow($alpha: 0.05, $x: 0, $y: 1) {
    box-shadow: #{$x * 0.4rem} #{$y * 0.4rem} 0.4rem 0
            rgba(0, 0, 0, $shadow-factor * $alpha),
        #{$x * 0.1rem} #{$y * 0.1rem} 0.1rem 0
            rgba(0, 0, 0, $shadow-factor * $alpha);
}

@mixin shadow-hover($alpha: 0.08) {
    box-shadow: 0 0.8rem 0.8rem 0 rgba(0, 0, 0, $shadow-factor * $alpha),
        0 0.2rem 0.2rem 0 rgba(0, 0, 0, $shadow-factor * $alpha);
}

.box-shadow {
    @include shadow;
}

// -----------------------------------------------------------------------------
// Borders
// -----------------------------------------------------------------------------

.rule-before {
    border-top: $rule;
}

.rule-after {
    border-bottom: $rule;
}

// -----------------------------------------------------------------------------
// Background
// -----------------------------------------------------------------------------

@mixin cover {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.bg-cover {
    @include cover;

    &.-fixed {
        background-attachment: fixed;
    }
}

.bg-contain {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.bg-parallax {
    background-size: 75%;
    background-position: center;
    background-repeat: no-repeat;
}

.bg-bg {
    background-color: $body-bg;
}

.bg-white {
    background-color: white;
}

.hover-bg-active:hover {
    background-color: $whitesmoke;
}

// -----------------------------------------------------------------------------
// Rounded corners
// -----------------------------------------------------------------------------

@mixin round() {
    border-radius: $border-radius;
}

.round {
    @include round;
}

@mixin circle($d) {
    width: $d;
    height: $d;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
}

// -----------------------------------------------------------------------------
// Flex
// -----------------------------------------------------------------------------

.flex-1 {
    flex: 1;
}

// -----------------------------------------------------------------------------
// Material icons
// -----------------------------------------------------------------------------

// see http://google.github.io/material-design-icons/

/* Rules for sizing the icon. */
.material-icons.md-12 {
    font-size: 12px;
}

.material-icons.md-18 {
    font-size: 18px;
}

.material-icons.md-24 {
    font-size: 24px;
}

.material-icons.md-36 {
    font-size: 36px;
}

.material-icons.md-48 {
    font-size: 48px;
}

/* Rules for using icons as black on a light background. */

.material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
}

.material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */

.material-icons.md-light {
    color: rgba(255, 255, 255, 1);
}

.material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
}

// Baseline correction

.align-icon {
    position: relative;
    bottom: -0.25em;
}

// -----------------------------------------------------------------------------
// Backdrop
// -----------------------------------------------------------------------------

.backdrop-25 {
    background-color: black;
    opacity: 0.25;
}

.backdrop-50 {
    background-color: black;
    opacity: 0.5;
}

.backdrop-75 {
    background-color: black;
    opacity: 0.75;
}

// -----------------------------------------------------------------------------
// Transforms
// -----------------------------------------------------------------------------

.rotate-180 {
    transform: rotate(180deg);
}

// -----------------------------------------------------------------------------
// Cursor
// -----------------------------------------------------------------------------

.cursor-pointer {
    cursor: pointer;
}

// -----------------------------------------------------------------------------
// Separators
// -----------------------------------------------------------------------------

.inline-separator-after:not(:last-of-type)::after {
    content: "|";
    display: inline-block;
    color: transparent;
    margin: 0 0.25em;
    border-right: $rule;
    opacity: 0.5;
}

// -----------------------------------------------------------------------------
// Overflow
// -----------------------------------------------------------------------------

.overflox-x-hidden {
    overflow-x: hidden;
}

.overflow-y-auto {
    overflow-y: auto;
}

// -----------------------------------------------------------------------------
// Other
// -----------------------------------------------------------------------------

// TODO refactor

.activity-filter-button {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    transition: all 200ms;
    margin-right: 0.1em;

    &:not(.active):hover {
        background-color: rgba($fg-color, 0.15);
    }

    &.active {
        background-color: $fg-color;
        color: $white;
    }

    .material-icons:not(.rotate-180) {
        position: relative;
        top: 0.1em;
    }
}

.hoverlay {
    transition: all 200ms;
}
