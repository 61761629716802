.hero {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    margin-top: -4.5rem;
    background-color: $light-blue;

    @include media-breakpoint-up(lg) {
        min-height: 90vh;
    }
}

.hero-price {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.hero-splash {
    @include heading;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    padding: 0.8em 2em 0.2em;
    font-size: 2.5rem;
    filter: drop-shadow(0 0.5rem 2rem rgba(0, 0, 0, 0.5));

    @include media-breakpoint-up(lg) {
        font-size: 4rem;
        margin-left: -1rem;
    }
}

.hero-price-splash {
    @include heading;

    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    // width: 70vw;
    // margin: 0 auto;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;

    color: white;
    margin: 0 auto;

    font-size: 3rem;

    & > div {
        margin-top: 1em;
        margin-bottom: 0.2em;
    }

    @include media-breakpoint-up(lg) {
        margin: 0;
        // height: 12rem;
        // width: 25rem;
    }
}

.hero-title,
.hero-subtitle {
    color: white;
    text-shadow: 0 0.1em 2em rgba(0, 0, 0, 0.6);
}

.hero-container {
    max-width: 45rem;
}
