.cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    z-index: 100;
    box-shadow: 0 -0.4rem 0.4rem 0 rgba(0, 0, 0, 0.02),
        0 -0.1rem 0.1rem 0 rgba(0, 0, 0, 0.02);
}
