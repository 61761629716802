// Colors

$torres: #fb7564;
$orange: #ff9312;
$yellow: #fbc04a;
$dark-blue: #2a4553;
$light-blue: lighten($dark-blue, 20%);
$white: #fff;
$whitesmoke: #e0e3e4;

$bg-color: #f6f6f6;
$fg-color: $dark-blue;
$primary: $torres;
$primary-alt: $orange;
$secondary: $dark-blue;

$body-color: $fg-color;
$body-bg: $bg-color;

$link-color: inherit;
$link-decoration: none;
$link-hover-color: darken($dark-blue, 15%);
$link-hover-decoration: none;

$text-muted: transparentize($dark-blue, 0.6);
$input-placeholder-color: $text-muted;

$border-color: transparentize($dark-blue, 0.8);
$rule: 1px solid $border-color;

// Typography

$system-font: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto;
$font-primary: "Montserrat", $system-font;
$font-secondary: "Kanit", $system-font;

$font-family-base: $font-primary;
$headings-font-family: $font-secondary;
$headings-font-weight: bold;
$headings-line-height: 1.5;

// Dimensions

$grid-gutter-width: 1rem;
$container-padding: 1rem;
