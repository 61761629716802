.order-form-section {
    &__header {
        display: flex;
        align-items: center;
        width: 100%;

        &.-disabled,
        &:disabled {
            color: $gray-400;

            .order-form-section__arrow {
                opacity: 0 !important;
            }
        }

        &:hover {
            background-color: $gray-100;
        }
    }
}
