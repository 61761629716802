.badge-label {
    @include small-caps;
    font-weight: bold;
    display: inline-block;
    color: $white;
    background-color: $primary;
    border-radius: $border-radius;
    padding: 0.25em 1em;
}

.collapse-arrow {
    display: inline-block;
    transform: rotate(180deg);
    transition: transform 200ms;

    .collapsed > & {
        transform: rotate(0);
    }
}
