body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    transition: all 0.2s;

    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;

    /* Remove excess padding and border in Firefox 4+ */
    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    &:not(:disabled):hover {
        cursor: pointer;
    }

    &:focus {
        outline: none !important;
    }
}

.material-icons {
    color: inherit;
}

.container,
.container-fluid {
    padding-left: $container-padding;
    padding-right: $container-padding;
}

.container {
    &.-sm {
        max-width: 576px;
    }

    &.-md {
        max-width: 768px;
    }
}
