.ticket-card {
    &__image-wrapper {
        margin-top: -0.75rem;
        flex: 1;
    }

    &__image {
        height: 100%;
        transition: all 0.2s;

        .ticket-card:hover & {
            transform: translateY(0.2rem);
        }
    }

    &__body {
        max-height: 16rem;
        overflow: hidden;
    }

    &__rating {
        line-height: 0.9rem;
    }

    @include media-breakpoint-up(lg) {
        height: 12rem;
    }
}
