@keyframes lightbox-show {
    0% {
        opacity: 0;
    }
    100% {
        oapcity: 1;
    }
}

.lightbox {
    position: fixed;
    inset: 0;
    background-color: rgb(0 0 0 / 80%);
    z-index: 99999;
    display: none;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    backdrop-filter: blur(10px);
    animation: lightbox-show 200ms ease;

    &--open {
        display: flex;
    }

    img {
        max-width: 60vw;
        max-height: 80vh;
    }

    .material-icons {
        color: white;
        font-size: 2em;
    }

    .lightbox-button {
        flex-shrink: 0;
        transition: background-color 200ms;
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;

        &:not(:disabled) {
            background-color: rgb(255 255 255 / 25%);

            &:hover {
                background-color: rgb(255 255 255 / 50%);
            }
        }

        &:disabled {
            opacity: 0;
        }
    }

    .close-button {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
    }
}
