.ico {
    transition: all 200ms;
    stroke: $fg-color;
}

.icon-star {
    fill: $gray-200;

    &.-active {
        fill: $yellow;
    }
}

.icon-clock {
    stroke: $fg-color;

    &.-muted {
        stroke: $gray-400;
    }
}

.icon-search {
    fill: $primary;
    transition: fill 200ms;

    .collapsed & {
        fill: $fg-color;
    }
}

.fix-baseline {
    position: relative;
    top: 0.2em;
}
