.slideshow {
    overflow: hidden;

    &__container {
        display: flex;
        flex-wrap: nowrap;
        transition: transform 400ms ease-in-out;
        will-change: transform;
        cursor: pointer;
    }

    &__slide {
        flex: none;
        width: 100vw;
    }
}

.slide-indicator {
    display: flex;
    justify-content: center;
    margin: 1rem 0;

    &__dot {
        @include circle(0.8rem);
        flex: none;
        margin: 0 0.5rem;
        border: 2px solid $yellow;
        transition: background-color 200ms, transform 200ms;

        &:hover {
            background-color: transparentize($yellow, 0.5);
            transform: scale(1.2);
        }

        &.-current {
            background-color: $yellow;
        }
    }
}
