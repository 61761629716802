.order-nav {
    display: flex;
    align-items: center;

    &__item {
        width: 6rem;
        text-align: center;
        border-bottom: 2px solid transparent;

        &.-active {
            opacity: 1;
            border-bottom: 2px solid $primary;
        }

        &:not([href]):not(.-active) {
            opacity: 0.4;
        }

        &[href]:hover {
            color: inherit;
            opacity: 0.6;
            border-bottom: 2px solid $primary;
        }
    }

    &__line {
        flex: 1;
        border-bottom: 1px solid $dark-blue;
        opacity: 0.1;
        align-self: flex-start;
        margin-top: 0.8rem;
    }
}

.payment-card-image {
    max-width: 3rem;
}

.coupon-input {
    &__status {
        transform: scale(0);
        width: 0;
        transition: all 200ms;

        &.-active {
            width: 2rem;
            transform: scale(1);
        }
    }
}
