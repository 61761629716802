// -----------------------------------------------------------------------------
// Style
// -----------------------------------------------------------------------------

@mixin all-caps {
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.all-caps {
    @include all-caps;
}

@mixin small-caps {
    @include all-caps;
    font-size: 0.75em;
}

.small-caps {
    @include small-caps;
}

.label {
    @include small-caps;
    font-weight: bold;
}

.text-unstyled {
    font: unset;
    color: unset;
    letter-spacing: unset;
    text-transform: unset;
}

// -----------------------------------------------------------------------------
// Size
// -----------------------------------------------------------------------------

.text-4xl {
    font-size: 3rem;
}

.text-3xl {
    font-size: 2rem;
}

.text-2xl {
    font-size: 1.5rem;
}

.text-xl {
    font-size: 1.25rem;
}

.text-lg {
    font-size: 1.125rem;
}

.text-sm {
    font-size: 0.875rem;
}

.text-xs {
    font-size: 0.75rem;
}

// -----------------------------------------------------------------------------
// Color
// -----------------------------------------------------------------------------

.text-primary {
    color: $primary;
}

.text-orange {
    color: $orange;
}

@mixin text-dim {
    opacity: 0.8;
}

.text-dim {
    @include text-dim;
}

.text-extra-muted {
    opacity: 0.2;
}

.text-light-blue {
    color: $light-blue;
}

// -----------------------------------------------------------------------------
// Line height
// -----------------------------------------------------------------------------

.line-height-1 {
    line-height: 1;
}

// -----------------------------------------------------------------------------
// Prose
// -----------------------------------------------------------------------------

// Link

@mixin a {
    display: inline-block;
    position: relative;
    border-bottom: 1px solid $yellow;
    transition: border 200ms;

    &:after {
        content: "";
        color: $orange;
        position: absolute;
        width: 100%;
        bottom: -2px;
        left: 0;
        border-bottom: 2px solid $yellow;
        transform: scaleX(0);
        transform-origin: left bottom;
        transition: transform 200ms;
    }

    &[href]:hover {
        border-bottom-color: transparentize($yellow, 0.5);

        &:after {
            transform: scaleX(1);
        }
    }
}

.a,
.article a {
    @include a;
}

.active-link {
    border-bottom: 2px solid $torres;
}

// Lists

@mixin list-expanded {
    li:not(:last-of-type) {
        margin-bottom: 1rem;
    }
}

.list-expanded {
    @include list-expanded;
}

// Headings

@mixin heading {
    font-family: $font-secondary;
}

.heading {
    @include heading;
}

@mixin subheading {
    @include heading;
    @include text-dim;
    font-weight: bold;
}

.subheading {
    @include subheading;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    @include all-caps;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    font-weight: bold;
}

.subsubheading {
    @include heading;
    @include text-dim;
    font-weight: 300 !important;
}
