.card {
    position: relative;
    border-radius: $border-radius;
    background-color: $white;
    transition: all 0.2s;
    display: flex;
    flex-direction: column;
    border: none;
    @include shadow;

    &.-hoverable:hover {
        @include shadow-hover;
    }

    &__hoverlay {
        background-color: black;
        opacity: 0;
        transition: all 200ms;

        .card:hover & {
            opacity: 0.2;
        }
    }
}

.card-image {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    flex: 1;

    img {
        height: 100%;
        object-fit: cover;
    }
}

.card-off-image {
    position: relative;
    padding: 0 1.5rem;
    top: -1.5rem;
}
