.section {
    overflow: visible;
    position: relative;

    &.-padded {
        padding: 4.5rem 0;
    }

    &.-padded-lg {
        padding: 6rem 0;
    }
}
