.modal-header {
    align-items: center;
    border-bottom: none;
}

.modal-footer {
    border-top: none;
}

.modal-content {
    border: none;
}
