.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: $white;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.02);
    z-index: 50;
    transition: transform 200ms ease-in-out;
    transform: translateY(0);

    &.-unpinned {
        transform: translateY(-4.5rem);
    }
}

.navbar-bar {
    display: flex;
    align-items: center;
}

.navbar-logo {
    svg {
        max-height: 3rem;
    }
}

.navbar-link {
    font-weight: 600;
    color: $dark-blue;

    &:hover {
        color: $light-blue;
        text-decoration: none;
        background-color: $bg-color;
    }

    &:visited {
        color: inherit;
    }
}

.navbar-small-link {
    display: block;
    line-height: 3rem;
    text-align: center;
    width: 50%;
    border-top: 1px solid $gray-200;
    padding: 0 1rem;

    &:nth-of-type(odd) {
        border-right: 1px solid $gray-200;
    }

    @include media-breakpoint-up(md) {
        width: 25%;

        &:not(:last-of-type) {
            border-right: 1px solid $gray-200;
        }
    }
}

.navbar-large-link {
    height: 4.5rem;
    padding: 1.5rem 2rem;
    font-weight: 600;
    margin: 0 auto;
}

.navbar-toggle {
    margin-left: -1rem;
}

.navbar-small {
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.navbar-large {
    display: none;

    @include media-breakpoint-up(lg) {
        display: flex;
    }
}

.navbar-item {
    height: 4.5rem;
    display: flex;
    align-items: center;

    &[aria-expanded="true"] {
        color: $primary;
    }
}

.navbar-body {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
    border-left: 1px solid $gray-200;
    border-right: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;
}

.navbar-dot {
    font-size: 0.5rem;
    color: black;
    opacity: 0.1;
}

.navbar-badge {
    @include circle(1.2rem);
    position: absolute;
    bottom: 1rem;
    left: 0;
    background-color: $orange;
    color: $white;
    font-size: 0.75em;
}
