.contact-map {
    overflow: hidden;
    background: none !important;
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    overflow: hidden;
    border: 1px solid $gray-300;

    @include shadow;

    iframe {
        height: 100%;
        width: 100%;
    }
}
