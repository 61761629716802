.footer-section {
    padding-top: 4rem;
    margin-top: 2rem;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: top center;

    @include media-breakpoint-up(lg) {
        background-size: 110% auto;
    }
}

.footer-link {
    @include text-dim;
    @include a;
}

.footer-card {
    padding: 3rem 3rem 1.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.footer-social-icon {
    vertical-align: middle;

    &.-facebook {
        fill: $fg-color;
        transition: fill 500ms ease;

        &:hover {
            fill: #3b5998;
        }
    }

    &.-instagram {
        stop {
            stop-color: $fg-color;
            transition: stop-color 200ms ease;
        }

        &:hover {
            stop:nth-of-type(1) {
                stop-color: #1400c8;
            }
            stop:nth-of-type(2) {
                stop-color: #b900b4;
            }
            stop:nth-of-type(3) {
                stop-color: #f50000;
            }
        }
    }

    &.-linkedin {
        fill: $fg-color;
        transition: fill 500ms ease;

        &:hover {
            fill: #0e76a8;
        }
    }

    &.-trip_advisor {
        background: url("/img/icons/trip_advisor.svg") no-repeat center center;
        height: 56px;
        width: 56px;

        &:hover {
            background-image: url("/img/icons/trip_advisor_color.svg");
        }
    }
}
