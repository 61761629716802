.main {
    flex: 1;
    padding-top: 4.5rem;
}

html {
    @include media-breakpoint-down(sm) {
        font-size: 14px;
    }
}
