.media-grid {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-right: -$grid-gutter-width;
    margin-bottom: -$grid-gutter-width;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
}

.media-block {
    display: block;
    margin-right: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;
    width: calc(100% - #{$grid-gutter-width});

    @for $i from 1 through 6 {
        @include media-breakpoint-up(md) {
            &.media-block-#{$i} {
                width: calc(#{100 / $i}% - #{$grid-gutter-width});
            }
        }
    }
}
