@mixin button-variant($bg, $fg: $white) {
    --button-padding-x: 1.5rem;
    --button-padding-y: 1rem;
    @include shadow($alpha: 0.1);
    position: relative;
    text-align: center;
    display: inline-block;
    padding: calc(var(--button-padding-y) - 4px) var(--button-padding-x);
    border-radius: 3rem;
    font-weight: 600;
    font-size: 0.9rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border: 2px solid currentColor;
    overflow: hidden;
    background-color: $bg;
    border-color: $bg;
    color: $fg;
    transition: background-color 200ms, color 200ms, box-shadow 200ms;

    &:disabled {
        opacity: 0.5;
    }

    &:not(:disabled):hover {
        background-color: lighten($bg, 8%);
        border-color: lighten($bg, 8%);
        color: $fg;
        @include shadow-hover($alpha: 0.12);
    }

    &.-ghost {
        background-color: transparent;
        border-color: $bg;
        color: $bg;

        &:not(:disabled):hover {
            background-color: $bg;
            border-color: $bg;
            color: $fg;
        }
    }

    .button__loading {
        background-color: $bg;
    }
}

.button {
    &.-primary {
        @include button-variant($primary);
    }

    &.-orange {
        @include button-variant($orange);
    }

    &.-yellow {
        @include button-variant($yellow);
    }

    &.-secondary {
        @include button-variant($secondary);
    }

    &.-white {
        @include button-variant($white, black);

        &.-active {
            color: $primary !important;
        }
    }

    &.-wide {
        min-width: 14rem;
    }

    &.-block {
        display: block;
        width: 100%;
    }

    &.-size-small {
        --button-padding-x: 1rem;
        --button-padding-y: 0.75rem;
    }

    &__loading {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        align-items: center;
        justify-content: center;
        border-radius: 3rem;
    }

    &.-loading &__loading {
        display: flex;
    }
}

.icon-button {
    @include shadow(0.1);
    transition: background-color 200ms;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background-color: rgb(255 255 255 / 100%);

    &:not(:disabled):hover {
        background-color: rgb(255 255 255 / 50%);
    }
}
