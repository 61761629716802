.intro-section {
    margin-top: 2rem;

    &__heading {
        padding-bottom: 2rem;
    }
}

.section-heading {
    @include media-breakpoint-down(md) {
        font-size: 1.5rem;
    }
}

.intro-section-heading {
    display: flex;
    align-items: center;

    img {
        margin-right: 1rem;
    }
}

.feature-image {
    height: 4rem;
}

.home-activity-card {
    &.-large {
        height: 31rem;
    }

    &.-small {
        height: 15rem;
    }
}

.home-count-section {
    &__body {
        min-height: 80vh;
    }
}

.review-card {
    &__header {
        margin-top: -0.5rem;
    }

    &__image {
        @include circle(5rem);
        // @include circle(8rem);
    }
}

.home-count-card {
    height: 16rem;
    background-position: bottom center;
    background-size: 100%;
    transition: background-size 200ms;

    &:hover {
        background-size: 105%;
    }

    &__heading {
        line-height: 1.2;
        font-size: 5rem;
    }

    &__content {
        transition: transform 200ms;

        .home-count-card:hover & {
            transform: scale(1.05);
        }
    }

    &__hoverlay {
        background-color: white;
        opacity: 0;

        .home-count-card:hover & {
            opacity: 0.8;
        }
    }
}

.section {
    &.-features {
        padding-top: 6rem;
        padding-bottom: 3rem;
    }
}

.parallax-layer {
    @include inset-0;
    position: absolute;
    z-index: -10;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 150%;

    @include media-breakpoint-up(md) {
        background-size: auto 80%;
    }

    @include media-breakpoint-up(lg) {
        background-size: auto 100%;
    }

    &.-actividades {
        top: -50px;
        bottom: -50px;
        background-image: url("/img/shapes/actividades-bg.svg");
    }

    &.-businesses {
        //bottom: -50px;
        background-image: url("/img/shapes/businesses-bg.svg");
    }

    &.-excursiones {
        bottom: -50px;
        background-image: url("/img/shapes/excursiones-bg.svg");
    }

    &.-references {
        top: 100px;
        bottom: -200px;
        background-image: url("/img/shapes/references-bg.svg");
    }

    &.-services {
        top: -200px;
        bottom: -200px;
        background-size: 100%;
        background-position: center;
        background-image: url("/img/shapes/services-bg.svg");
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }
}
