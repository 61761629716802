.activity-detail-header {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.activity-detail-typography {
    h3 {
        @extend .h5;
        @extend .text-dim;
        font-weight: bold;
    }
}

.activity-hero {
    overflow: hidden;
    position: relative;
    margin-top: -4.5rem;
    height: 100vh;

    &__backdrop {
        background-color: $dark-blue;
        mix-blend-mode: multiply;
    }
}

.activity-subnav {
    z-index: 20;

    &__header {
        max-width: 0;
        flex: none;
        overflow: hidden;
        transition: all 400ms;
        white-space: nowrap;
        opacity: 0;
    }

    &.-expand &__header {
        transition: all 400ms;
        display: block;
        max-width: 15rem;
        opacity: 1;
    }

    &__link {
        &:hover {
            background-color: $bg-color;
        }
    }

    &__order-button {
        fill: white;

        @include media-breakpoint-down(md) {
            line-height: 1;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}
