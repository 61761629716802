.error-page {
    position: relative;
    min-height: 100vh;

    background-size: 80%;
    background-position: 50% 60%;
    background-repeat: no-repeat;

    @include media-breakpoint-up(lg) {
        background-position: 80% 20%;
        background-size: 60%;
    }
}

.error-page-content {
    position: absolute;
    top: 15%;

    @include media-breakpoint-up(lg) {
        top: 20%;
    }
}
