.clndr {
    td {
        text-align: center;
        font-size: 0.875rem;
        transition: all 200ms;
    }

    .day {
        &:not(.past),
        &:not(.empty) {
            cursor: pointer;

            &:hover .day-contents {
                background-color: transparentize($light-blue, 0.8);
            }

            &.selected:hover .day-contents {
                background-color: $orange;
            }
        }

        &.past,
        &:not(.event) {
            opacity: 0.3;
        }

        &.adjacent-month {
            opacity: 0;
            cursor: default;
        }
    }

    .day-contents {
        @include circle(2.2rem);
        transition: all 200ms;
        margin: 0.4em auto;
    }

    .today .day-contents {
        border: 2px solid $primary;
        color: $primary;
        font-weight: 600;
    }

    .selected .day-contents {
        background-color: $primary;
        color: $white;
        font-weight: 600;
    }

    .header-day {
        @include small-caps;
        height: 2.5rem;
        font-weight: 600;
        opacity: 0.5;
    }

    .clndr-controls {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        .month {
            @include small-caps;
            opacity: 0.6;
            font-weight: 600;
            margin: 0 auto;
        }
    }

    .clndr-control-button {
        @include small-caps;
        font-weight: bold;
        cursor: pointer;
        transition: all 200ms;
        opacity: 0.6;

        &:not(:disabled):hover {
            color: $primary;
        }
    }

    .clndr-table {
        width: 100%;
    }
}
